export const BannerMedia = [
    {
      Id: 1,
      MediaUrl: 'https://risingtheme.com/html/demo-newvilla/assets/img/other/hero-layer-img.png',
      FileType: 'image/jpeg',
    },
    {
      Id: 2,
      MediaUrl: 'https://risingtheme.com/html/demo-newvilla/assets/img/other/hero-layer-img2.png',
      FileType: 'image/jpeg',
    },
    {
      Id: 3,
      MediaUrl: 'https://risingtheme.com/html/demo-newvilla/assets/img/other/hero-layer-img.png',
      FileType: 'image/png',
    },
    {
      Id: 4,
      MediaUrl: 'https://risingtheme.com/html/demo-newvilla/assets/img/other/hero-layer-img2.png',
      FileType: 'image/png',
    },
  ];
  