import React, { useEffect, useRef } from "react";
import "./landingPage.css";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import Typed from "typed.js";
import theme from "../Components/Theme/theme";
import Swiper from "swiper";
import { BannerMedia } from "../Components/Constant";
import Carousel from "../Components/Swiper/Swiper";
import { SwiperSlide } from "swiper/react";

const LandingPage = () => {
  const typedElement = useRef(null);

  const bannerCarouselProperties = {
    autoplay: {
      delay: 2100,
    },
    pagination: false,
    navigation: false,
    breakpoints: {
      600: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      960: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      1280: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
    },
    loop: true,
  };
  const snippetsCarouselProperties = {
    autoplay: {
      delay: 15000,
    },
    pagination: true,
    breakpoints: {
      600: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      960: {
        slidesPerView: 2.3,
        spaceBetween: 20,
      },
      1280: {
        slidesPerView: 2.3,
        spaceBetween: 20,
      },
    },
    loop: true,
  };

  const mobileCarousalProperties = useMediaQuery(theme.breakpoints.down("sm"))
    ? snippetsCarouselProperties
    : bannerCarouselProperties;

  useEffect(() => {
    const options = {
      strings: ["Paradise", "Desires", "Residence"],
      typeSpeed: 100,
      backSpeed: 60,
      loop: true,
    };

    const typed = new Typed(typedElement.current, options);

    return () => {
      typed.destroy();
    };
  }, []);
  return (
    <div className="mainWrapper">
      <Grid container height={"100%"} alignItems={"center"}>
        <Grid item xs={12} md={6} textAlign={"center"}>
          <div className="leftWrapper">
            <Typography variant="caption">18,000+ TRUST CUSTOMERS</Typography>
            <Typography variant="h1">
              Discover Your Dream Home{" "}
              <span className="typoAimation" ref={typedElement}></span>
            </Typography>
            <Typography variant="body1">
              Welcome to our collection of exceptional properties, where your
              dream home awaits. Explore our diverse range of homes designed to
              suit every lifestyle and aspiration. From charming retreats to
              modern marvels, we're here to help you find the perfect place to
              call your own. - StupidBasket.com Test
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div>
            <Carousel CarousalProperties={mobileCarousalProperties}>
              {BannerMedia.map((cardData, key) => {
                return (
                  <>
                    {
                      <SwiperSlide key={key}>
                        <img
                          src={cardData.MediaUrl}
                          alt={`Event ${key + 1}`}
                          height="100%"
                        />
                      </SwiperSlide>
                    }
                  </>
                );
              })}
            </Carousel>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingPage;
