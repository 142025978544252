import { Swiper } from "swiper/react";
// import { useMediaQuery, useTheme } from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Autoplay, Navigation, Keyboard } from "swiper/modules";
import "./style.css";

const Carousel = ({
  children,
  CarousalProperties,
}) => {
  const default_breakpoints = {
    600: { slidesPerView: 1, spaceBetween: 10 },
    960: { slidesPerView: 1, spaceBetween: 20 },
    1280: { slidesPerView: 2, spaceBetween: 20 },
    1920: { slidesPerView: 3, spaceBetween: 20 },
  };

  const modules = [Keyboard];
  if (CarousalProperties?.autoplay !== false) modules.push(Autoplay);
  if (CarousalProperties?.pagination) modules.push(Pagination);
  if (CarousalProperties?.navigation) modules.push(Navigation);
  

  return (
    <>
      <Swiper
        slidesPerView={"auto"}
        data-testid="Swiper"
        initialSlide={CarousalProperties?.selectedSlide}
        effect={CarousalProperties?.EffectFlip ? "flip" : "slide"}
        spaceBetween={20}
        speed={CarousalProperties?.speed ? CarousalProperties?.speed : 500}
        navigation={
          CarousalProperties?.navigation
            ? CarousalProperties?.navigation
            : false
        }
        autoplay={
          CarousalProperties?.autoplay
            ? CarousalProperties?.autoplay
            : {
              delay: 15000,
              disableOnInteraction: false,
              pauseOnMouseEnter: false,
            }
        }
        pagination={{
          clickable: true,
        }}
        breakpoints={
          CarousalProperties?.breakpoints
            ? CarousalProperties?.breakpoints
            : (default_breakpoints)
        }
        loop={CarousalProperties?.loop ? CarousalProperties?.loop : false}
        modules={modules}
        className="customSwiper"
        keyboard={{
          enabled: true,
        }}
      >
        {children}
      </Swiper>
    </>
  );
};

export default Carousel;
