import React, { useState } from "react";
import {
  Stack,
  Link,
  Toolbar,
  Typography,
  Container,
  AppBar,
  Button,
  Drawer,
  Box,
  styled
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

// Define styled components
const StyledButton = styled(Button)(({ theme }) => ({
  color: "black",
  display: "flex",
  [theme.breakpoints.up('sm')]: {
    display: "none",
  },
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  display: "inherit",
  [theme.breakpoints.up('sm')]: {
    display: "none",
  },
  "& .MuiDrawer-paper": {
    height: "100%",
    width: "100%",
  },
}));

const CloseButton = styled(Button)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: theme.spacing(2),
}));

const NavList = styled(Stack)(({ theme }) => ({
  overflow: "auto",
  flexDirection: "column",
  gap: theme.spacing(3),
  width: "100%",
  textAlign: "center",
  fontSize: "22px",
  [theme.breakpoints.up('sm')]: {
    flexDirection: "row",
    justifyContent:'center',
    textAlign: "initial",
    fontSize: "initial",
  },
}));

const NavLink = styled(Link)(({ theme }) => ({
  color: "black",
  textDecoration: "none",
  fontWeight: "bold",
  "&:hover": {
    color: "#FF4081",
  },
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: "linear-gradient(to right, #ffffff, #f2f2f2)",
  boxShadow: "none",
  borderBottom: "1px solid #e0e0e0",
}));

const PurchaseButton = styled(Button)(({ theme }) => ({
  color: "black",
  borderColor: "#FF4081",
  borderRadius: "20px",
  fontWeight: "bold",
  textWrap: "nowrap",
  "&:hover": {
    borderColor: "#FF4081",
    backgroundColor: "#FF4081",
    color: "white",
  },
}));

const pages = [
  { name: "Products", id: "products" },
  { name: "Services", id: "services" },
  { name: "About", id: "about" },
  { name: "Testimonials", id: "testimonials" },
  { name: "Contact", id: "contact" },
];

const Nav = () => {
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <>
      <StyledButton variant="text" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </StyledButton>
      <StyledDrawer open={open} onClose={toggleDrawer(false)} anchor="right">
        <CloseButton onClick={toggleDrawer(false)}>
          <CloseIcon />
        </CloseButton>
        <NavList>
          {pages.map((page) => (
            <NavLink key={page.id}>
              {page.name}
            </NavLink>
          ))}
        </NavList>
      </StyledDrawer>
      <NavList>
        {pages.map((page) => (
          <NavLink key={page.id}>
            {page.name}
          </NavLink>
        ))}
      </NavList>
    </>
  );
};

const Header = () => {
  return (
    <StyledAppBar position="static">
      <Container>
        <Toolbar>
          <Stack  justifyContent="space-between" alignItems="center" width="100%" flexDirection='row'>
            <Typography variant="h6" sx={{ color: "black", fontWeight: "bold" }}>
              NewVilla
            </Typography>
            <Nav />
            <PurchaseButton variant="outlined">
              Purchase Now
            </PurchaseButton>
          </Stack>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};

export default Header;
